<template>
  <chevron class="transform rotate-90 absolute right-0 bottom-0 mr-2 mb-2" v-bind:class="{'text-signal-error' : validationErrors, 'text-primary' : !validationErrors}"></chevron>
</template>

<script>

import Chevron from "../../components/svg/chevron.vue";

export default {
  name: 'dropdownArrowSvg',
  components: {Chevron},
  props: {
    validationErrors: [String, Boolean]
  }
}
</script>
