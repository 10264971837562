<template>
  <div
    class="bg-white w-full inline-block border-grey-dark border-1 lg:border-0 lg:border-l-1 rounded-tl-1 rounded-bl-1 md:rounded lg:rounded-none"
  >
    <label for="adults" class="absolute pt-1 pl-2 text-xs">Adult</label>
    <div class="relative flex items-center">
      <select
        v-model="adultsSelected"
        name="adults"
        id="adults"
        class="appearance-none w-full bg-transparent relative z-1 p-1 pt-4 pl-2 pr-6 text-lg font-bold h-10"
        @focus="handleFocusAdults"
        @change="handleSelectAdults"
      >
        <option
          v-for="n in adultsChoices"
          :key="n"
          :value="n"
          @click="
            $generalClick({
              category: 'Search Gadget Interaction',
              label: `Number of Adults_${adultsSelected}`,
            })
          "
        >
          {{ n }}
        </option>
      </select>
      <dropdownArrowSvg></dropdownArrowSvg>
    </div>
  </div>
</template>

<script>
import dropdownArrowSvg from "../../components/svg/gadgetDropDownArrow.vue";

export default {
  props: { adults: Number },
  components: { dropdownArrowSvg },
  data() {
    return {
      adultsChoices: 9,
      adultsSelected: this.adults || 2,
    };
  },
  created() {
    this.$store.dispatch("addAdults", this.adultsSelected);
  },
  methods: {
    handleFocusAdults() {
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: "Open Number of Adults",
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "adults",
        action: "open",
      });
    },
    handleSelectAdults(event) {
      let adults = parseInt(event.target.value);
      if (isNaN(adults)) {
        adults = 2;
      }
      this.$store.dispatch("addAdults", adults);
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Number of Adults_${adults}`,
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "adults",
        action: "select",
        content_display: adults
      });
    },
  },
};
</script>
