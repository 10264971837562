<template>
  <div class="w-full md:w-1/2 lg:w-3/12 mb-2 relative md:pl-1 lg:pl-0">
    <div
      class="bg-white rounded border-1 border-grey-dark lg:border-0"
      :class="{ 'bg-grey-light sg__error': showValidationErrors }"
    >
      <auto
        @input="handleLocationInput"
        channel="Holidays"
        ga-label="Going To"
        ga4-label="destination"
        :endpoint="endpoint"
        display-value="name"
        id="holidays-destination"
        placeholder="Enter Destination"
        label="Destination"
        :validation-errors="validationErrors"
        :show-validation-errors="showValidationErrors"
        :value="destinationSelected"
        ref="holidaysDestination"
        :showAnyDestination="true"
      ></auto>
    </div>
  </div>
</template>

<script>
import Auto from "../../components/holidaysGadget/AutoComplete.vue";

export default {
  components: { Auto },
  props: { destination: Object, validationErrors: String },
  data() {
    return {
      destinationSelected: this.destination || {},
      endpoint: "/global/reference-data/v1/locations/holidays/search/",
    };
  },
  created() {
    if (this.destination) {
      const destination = {
        name: this.destinationSelected.name,
        id: this.destinationSelected.id,
      };
      this.setDestination(destination);
    }
  },
  methods: {
    handleLocationInput(selectedItem) {
      this.$store.dispatch(
        "setDestination",
        selectedItem ? selectedItem : null
      );
    },
    setDestination(destination) {
      this.$store.dispatch("setDestination", {
        name: destination.name,
        id: destination.id,
      });
    },
    prePopulateDestination(searchTerm) {
      if (!this.destination) {
        import(/* webpackChunkName: "tsm-axios" */ "axios").then((Axios) => {
          Axios.get(`${this.apiDomain}${this.endpoint}${searchTerm}`)
            .then((response) => {
              const destination = response.data.locations[0];
              this.setDestination(destination);
              this.$refs.holidaysDestination.updateVisibleText(
                destination.name
              );
            })
            .catch(() => {
              //Do nothing, we just need to handle the error and continue.
            });
        });
      }
    },
  },
  mounted() {
    this.apiDomain =
      window.location.hostname === "www.travelsupermarket.com"
        ? this.$store.getters.apiUrlProd
        : this.$store.getters.apiUrlTest;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const destinationName = urlSearchParams.get("destinationName");

    if (destinationName && !this.destination) {
      this.prePopulateDestination(destinationName);
    }

    if (this.destination) {
      // TODO: due to the v-modal bug, see updateVisibleText function in AutoComplete.vue
      // this will mean the input does not have value from SSR, and only on Vue mount
      this.$refs.holidaysDestination.updateVisibleText(
        this.destinationSelected.name
      );
    }
  },
  computed: {
    showValidationErrors() {
      if (!this.$store.getters.destination && this.validationErrors) {
        return true;
      }
      return false;
    },
  },
};
</script>
