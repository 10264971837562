var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full inline-block relative"},[_c('div',{staticClass:"bg-white rounded-tr-1 rounded-br-1 md:rounded lg:rounded-none border-1 border-l-0 md:border-l-1 lg:border-0 lg:border-l-1 border-grey-dark"},[_c('label',{staticClass:"absolute pt-1 pl-2 text-xs",attrs:{"for":"numChild"}},[_vm._v("Child (0-17)")]),_c('div',{staticClass:"relative flex items-center"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.numChildren),expression:"numChildren"}],staticClass:"appearance-none w-full bg-transparent relative z-1 p-1 pt-4 pl-2 pr-6 text-lg font-bold h-10",attrs:{"id":"numChild"},on:{"focus":_vm.handleFocusChildren,"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.numChildren=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.handleSelectChildren($event)}]}},_vm._l((5),function(n){return _c('option',{key:("numChild-" + (n - 1)),domProps:{"value":n - 1}},[_vm._v("\n          "+_vm._s(n - 1)+"\n        ")])}),0),_c('dropdownArrowSvg')],1)]),(_vm.childrenAges.length > 0)?_c('div',{staticClass:"bg-white flex mt-2 relative md:absolute lg:relative rounded-1 lg:rounded-none sg__child-ages"},_vm._l((_vm.childrenAges),function(child,idx){return _c('div',{key:("childAges-" + idx),staticClass:"relative flex items-center p-0 border-1 lg:border-0 lg:border-t-1 border-grey-dark lg:rounded-none",class:{
        'border-l-0 lg:border-l-1': idx !== 0,
        'rounded-tl-1 rounded-bl-1' : idx === 0 && _vm.childrenAges.length > 1,
        'rounded-tr-1 rounded-br-1' : idx + 1 === _vm.childrenAges.length && _vm.childrenAges.length > 1,
        'w-full rounded': _vm.childrenAges.length === 1,
        'w-1/2': _vm.childrenAges.length === 2,
        'w-4/12': _vm.childrenAges.length === 3,
        'w-3/12': _vm.childrenAges.length === 4,
        'bg-grey-light sg__error': _vm.validateThisAge(idx)
      }},[_c('div',{staticClass:"w-full"},[(!_vm.validateThisAge(idx))?_c('label',{staticClass:"absolute pt-1 pl-2 text-xs",attrs:{"for":("childAges-" + idx)}},[_vm._v("Age")]):_vm._e(),(_vm.validateThisAge(idx))?_c('label',{staticClass:"absolute pt-1 pl-2 text-xs text-signal-error",class:{ 'text-signal-error': _vm.validateThisAge(idx) },attrs:{"data-cy":"gadget-childage-error","for":("childAges-" + idx)}},[_vm._v("Age")]):_vm._e(),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.childrenAges[idx]),expression:"childrenAges[idx]"}],staticClass:"appearance-none w-full bg-transparent relative z-1 p-1 pt-4 pl-2 pr-6 text-lg font-bold h-10",class:{ 'text-signal-error': _vm.validateThisAge(idx) },attrs:{"id":("childAges-" + idx)},on:{"focus":_vm.handleFocusChildrenAge,"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.childrenAges, idx, $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.handleSelectAge($event)}]}},[_c('option',{attrs:{"value":"?","selected":""}},[_vm._v("?")]),_vm._l((_vm.maxAge),function(age,idx){return _c('option',{key:idx,domProps:{"value":idx}},[_vm._v("\n            "+_vm._s(idx)+"\n          ")])})],2),_c('dropdownArrowSvg',{attrs:{"validation-errors":_vm.validateThisAge(idx)}})],1)])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }