<template>
  <div class="w-1/2 lg:w-4/12 lg:pl-2 pr-2">
    <div
      class="relative flex items-center"
      :class="{ 'bg-white': showOptions }"
    >
      <svg
        aria-hidden="true"
        data-cy="gadget-board-icon"
        class="absolute left-0 ml-2 text-text-link fill-current"
        width="11"
        height="15"
        viewBox="0 0 11 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Board Basis icon</title>
        <g fill-rule="evenodd">
          <path
            d="M3.03637943 5.12744419h.78403294V.83917333C3.83465303-.21245891 4.99900122-.24910512 4.99929997.6443598c.00049793 1.82604445-.00159336 3.65218384.0006971 5.47822829.00099585.77678568-.25125304 1.37850502-1.08816564 1.68591543-.15266385.05601364-.26648954.39997532-.2673858.61149799-.01633195 3.98949988-.01145228 1.33350423-.01513693 5.32309898-.00079668.8326095-.52331934 1.3395803-1.2839498 1.2457812-.55687949-.0686404-.91199971-.3609556-.98051422-.9111234-.02688795-.2158899-.02698754-.4354823-.02708712-.6534608-.00119502-3.87054206-.00258922-1.0953988.0032863-4.96594088.00049793-.35155675-.00647303-.59203562-.46874674-.74877886-.57659733-.19547809-.87585035-.68431571-.87226528-1.31470643C.01068744 4.4894584.00311897 2.5838556.00272064.67844268.00252145-.27929552 1.1458572-.11752055 1.1171767.51201573V5.1217479c.25643146.01443063.47113678.02658274.75425703.04253239V.77157721C1.84295242-.11752055 3.03797279-.30739727 3.03637943.5817005v4.5457437zM10.9944398 7.01046791c0 3.43098869.002361 3.10405789-.0016527 6.53504659-.0009443.8051141-.4040816 1.2931617-1.14247789 1.4201762-1.06728074.1835698-1.8573825-.3839558-1.85962543-1.3561053-.00684684-2.8555717-.01027026-1.9533177.00177073-4.80879544.00153464-.37512494-.09219624-.63779695-.4804593-.91277583-.29299755-.20752592-.48270224-.62821449-.48600762-.95542715-.03435225-3.4520325-.0282137-2.20715884-.01782539-5.65937924.00236098-.8034231.59732779-1.2558653 1.59413329-1.27127238.21414084-.0032881.42851777-.00169103.6426586-.00028184 1.20103021.00789143 1.74582621.4329015 1.74948571 1.41322425.0100342 2.68515425.0034234.67293261.0034234 3.35799292v2.23759722h-.0034234z"
          />
        </g>
      </svg>

      <button
        aria-haspopup="true"
        v-bind:aria-expanded="showOptions ? 'true' : 'false'"
        id="holidaysBoardAndCatering"
        class="appearance-none border-0 rounded-none bg-transparent pt-2 pr-6 pb-2 pl-6 text-secondary w-full relative z-1 text-left text-sm truncate cursor-pointer"
        :class="{
          'border-1 border-primary border-b-0 text-secondary': showOptions,
          'border-b-1 border-grey-dark': !showOptions,
        }"
        @click="toggleOptions($event)"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
        @keydown.space="onEnter"
        @keydown.tab="closeOptions"
        @keydown.esc="closeOptions"
      >
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          width="18"
          viewBox="0 0 185.343 185.343"
          class="text-secondary fill-current transform rotate-90 absolute right-0 mr-2"
          :class="{ 'text-primary': showOptions }"
        >
          <path
            d="M51.707 185.343a10.692 10.692 0 0 1-7.593-3.149 10.724 10.724 0 0 1 0-15.175l74.352-74.347L44.114 18.32c-4.194-4.194-4.194-10.987 0-15.175 4.194-4.194 10.987-4.194 15.18 0l81.934 81.934c4.194 4.194 4.194 10.987 0 15.175l-81.934 81.939a10.678 10.678 0 0 1-7.587 3.15z"
          />
        </svg>
        {{ boardBasisDisplay }}
      </button>
      <fieldset
        v-show="showOptions"
        :aria-hidden="showOptions ? 'false' : 'true'"
      >
        <legend class="absolute -left-9999">Board and catering options</legend>
        <ul
          id="holidaysBoardAndCateringList"
          role="menu"
          class="absolute z-1 top-full left-0 bg-white w-full"
          :class="{ 'border-1 border-primary border-t-0': showOptions }"
        >
          <li
            v-for="(boardBasis, i) in boardBasisMap"
            :key="boardBasis.value"
            role="menuitem"
          >
            <input
              v-model="boardBasisSelected"
              @change="handleBoardBasis($event, boardBasis.label)"
              :data-vars-board-basis="`Board Basis_${boardBasis.label}`"
              :id="`gadget_boardAndCatering_${boardBasis.value}`"
              class="hidden"
              type="checkbox"
              name="boardBasis"
              :value="boardBasis.value"
            />
            <label
              class="flex w-full p-2 relative items-center hover:bg-primary-lighter"
              :class="{ 'bg-primary-lighter': i === arrowCounter }"
              :for="`gadget_boardAndCatering_${boardBasis.value}`"
            >
              <span
                class="bg-grey-lighter flex items-center justify-center mr-2 h-5 min-w-5 w-5"
              >
                <svg
                  v-if="isSelected(boardBasis.value)"
                  xmlns="http://www.w3.org/2000/svg"
                  class="text-primary fill-current h-3 w-3"
                  height="11"
                  width="14"
                  viewBox="0 0 15 12"
                >
                  <path
                    d="M15 1.6L4.6 12 0 7.3l1.5-1.6 3.1 3.1L13.4 0 15 1.6z"
                  />
                </svg> </span
              >{{ boardBasis.label }}</label
            >
          </li>
        </ul>
      </fieldset>
    </div>
  </div>
</template>

<script>
import Constants from "../../Constants";
export default {
  props: { boardBasis: Array },
  data() {
    return {
      showOptions: false,
      boardBasisSelected: this.boardBasis || [],
      boardBasisMap: Constants.BOARD_BASIS_MAP,
      arrowCounter: -1,
    };
  },
  created() {
    const allPossibleBoardBasis = this.boardBasisMap.map(
      (board) => board.value
    );
    this.boardBasisSelected.forEach((board) => {
      if (allPossibleBoardBasis.includes(board)) {
        this.$store.dispatch("addBoardBasis", board);
      }
    });
  },
  methods: {
    sendToGa(payload) {
      this.$generalClick(payload);
    },
    isSelected(val) {
      return this.boardBasisSelected.indexOf(val) > -1;
    },
    toggleOptions(event) {
      event.preventDefault();
      this.showOptions = !this.showOptions;
      if (this.showOptions) {
        this.sendToGa({
          category: "Search Gadget Interaction",
          label: "Open Board Basis Filter",
        });
        this.$ga4GeneralClick({
          event: "search_gadget",
          product: "holidays",
          module: "board basis",
          action: "open",
        });
      }
      else {
        this.sendGa4CloseEvent()
      }
    },
    sendGa4CloseEvent(){
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "board basis",
        action: "close",
      });
    },
    closeOptions() {
      if(this.showOptions){
        this.sendGa4CloseEvent()
      }
      this.showOptions = false;
      this.arrowCounter = -1;
    },
    handleBoardBasis(event, label) {
      const checkboxStatus = event.target.checked;
      if (checkboxStatus) {
        this.$store.dispatch("addBoardBasis", event.target.value);
      } else {
        this.$store.dispatch("removeBoardBasis", event.target.value);
      }
      this.sendToGa({
        category: "Search Gadget Interaction",
        label: `Board Basis_${event.target.value}`,
        action: `${checkboxStatus ? "Select" : "Deselect"}`,
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "board basis",
        content: event.target.value,
        content_display: label.toLowerCase(),
        action: `${checkboxStatus ? "select" : "deselect"}`,
      })
    },
    onArrowDown(evt) {
      evt.preventDefault();
      if (this.showOptions) {
        if (this.arrowCounter < this.allPossibleBoardBasis.length - 1) {
          this.arrowCounter = this.arrowCounter + 1;
        } else {
          this.arrowCounter = 0;
        }
      }
    },
    onArrowUp(evt) {
      evt.preventDefault();
      if (this.showOptions) {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        } else {
          this.arrowCounter = this.allPossibleBoardBasis.length - 1;
        }
      }
    },
    onEnter(evt) {
      evt.preventDefault();
      if (!this.showOptions) {
        this.showOptions = true;
        this.sendToGa({
          category: "Search Gadget Interaction",
          label: "Open Board Basis Filter",
        });
        this.$ga4GeneralClick({
          event: "search_gadget",
          product: "holidays",
          module: "board basis",
          action: "open",
        });
      } else {
        if (this.arrowCounter >= 0) {
          const val = this.boardBasisMap[this.arrowCounter].value;
          const ga = {
            category: "Search Gadget Interaction",
            label: `Board Basis_${val}`,
          };
          const ga4 = {
            event: "search_gadget",
            product: "holidays",
            module: "board basis",
            content: val,
            content_display: this.boardBasisMap[this.arrowCounter].label.toLowerCase()
          }
          if (this.isSelected(val)) {
            this.boardBasisSelected = this.boardBasisSelected.filter(
              (bb) => bb !== val
            );
            this.$store.dispatch("removeBoardBasis", val);
            ga.action = "Deselect";
            ga4.action = "deselect";
          } else {
            this.boardBasisSelected.push(val);
            this.$store.dispatch("addBoardBasis", val);
            ga.action = "Select";
            ga4.action = "select";
          }
          this.sendToGa(ga);
          this.$ga4GeneralClick(ga4)
        } else {
          this.closeOptions();
        }
      }
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        if (this.showOptions) {
          this.closeOptions();
        }
      }
    },
  },
  computed: {
    allPossibleBoardBasis() {
      return this.boardBasisMap.map((board) => board.value);
    },
    boardBasisDisplay() {
      if (this.boardBasisSelected.length === 0) {
        return "Any";
      } else if (this.boardBasisSelected.length === 1) {
        const boardBasisDisplay = this.boardBasisMap.filter(
          (board) => board.value === this.boardBasisSelected[0]
        );
        return boardBasisDisplay[0].label;
      }
      return "Multiple";
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
};
</script>