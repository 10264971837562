export const ScrollIntoView = {
    
    update(el, binding) {

        if(binding.value > -1){
            const listHeight = el.offsetHeight
            const childHeight = el.children[binding.value].offsetHeight
            const childOffset = el.children[binding.value].offsetTop
            const scrollTopVal = childOffset

            if(childOffset > (listHeight - childHeight)){
                el.scrollTop = scrollTopVal
            }
            else {
                el.scrollTop = 0
            }
        }
    },
    unbind(el) {
        el.scrollTop = 0
    }
};