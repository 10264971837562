import { render, staticRenderFns } from "./Stars.vue?vue&type=template&id=5c812384&"
import script from "./Stars.vue?vue&type=script&lang=js&"
export * from "./Stars.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "7ce92e3d"
  
)

export default component.exports