<template>
  <div class="w-full md:w-1/2 lg:w-3/12 md:pr-1 lg:pr-0 relative">
    <div
      class="bg-white w-full flex items-center"
      v-for="(airportComponent, idx) in departureDropdowns"
      :key="airportComponent.elementId"
      v-bind:class="{
        'mt-2': idx !== 0,
        'mb-2 lg:mb-0': idx === 2,
        'lg:border-r-1 border-grey-dark': idx === 0,
      }"
    >
      <div
        class="border-1 lg:border-0 border-grey-dark rounded lg:rounded-none"
        :class="{
          'w-full': idx === 0,
          'flex-1': idx !== 0,
          'bg-grey-light sg__error': showValidationErrors,
        }"
      >
        <label
          v-if="!showValidationErrors"
          :for="`holidays-departure-${idx}`"
          class="absolute pt-1 pl-2 lg:pl-0 text-xs"
          >Depart from</label
        >
        <label
          data-cy="gadget-departure-error"
          v-if="showValidationErrors"
          :for="`holidays-departure-${idx}`"
          class="absolute pt-1 pl-2 lg:pl-0 text-xs text-signal-error"
          >{{ validationErrors }}</label
        >

        <div class="relative flex items-center">
          <select
            :id="airportComponent.elementId"
            :data-cy="airportComponent.elementId"
            name="airportComponent.elementId"
            class="
              appearance-none
              w-full
              bg-transparent
              relative
              z-1
              pt-4
              pr-6
              pb-1
              pl-2
              lg:pl-0
              text-lg
              font-bold
              h-10
            "
            v-bind:class="{ 'text-signal-error': showValidationErrors }"
            v-model="selectedDeparturesValues[idx]"
            @focus="handleFocusDeparture(idx)"
            @change="handleSelectDeparture($event, idx)"
          >
            <option value class>Select Location</option>
            <optgroup v-if="airportComponent.hasRegional" label="region">
              <option
                v-for="region in departuresMap.regions"
                :key="region.value"
                :label="region.label"
                :value="region.value"
              >
                {{ region.label }}
              </option>
            </optgroup>

            <optgroup label="airport">
              <option
                v-for="airport in departuresMap.airports"
                :key="airport.value"
                :label="airport.label"
                :value="airport.value"
              >
                {{ airport.label }}
              </option>
            </optgroup>
          </select>

          <dropdownArrowSvg
            :validation-errors="validationErrors"
          ></dropdownArrowSvg>
        </div>
      </div>
      <button
        v-if="airportComponent.closable"
        :id="`remove-${airportComponent.elementId}`"
        aria-label="Remove departure"
        @click="handleDepartureRemove($event, airportComponent.elementId)"
        type="button"
        class="mr-2 ml-2 p-0 bg-transparent"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          title="Remove location"
          class="fill-current transform rotate-45"
          height="20"
          width="20"
          viewBox="0 0 12 12"
        >
          <path
            d="M11.6 3.7c.3.8.4 1.5.4 2.3s-.1 1.5-.4 2.3c-.3.7-.7 1.4-1.3 2-.6.6-1.2 1-2 1.3-.8.3-1.5.4-2.3.4s-1.5-.1-2.3-.4c-.7-.3-1.4-.7-2-1.3-.6-.6-1-1.2-1.3-2S0 6.8 0 6s.1-1.5.4-2.3.7-1.4 1.3-2c.6-.6 1.2-1 2-1.3S5.2 0 6 0s1.5.1 2.3.4 1.4.7 2 1.3c.5.6 1 1.3 1.3 2zm-5 5.8l.1-2.9h2.9V5.4h-3v-3H5.4v2.9h-3v1.2h3v3h1.2z"
          />
        </svg>
      </button>
    </div>

    <button
      v-if="showAddDeparture()"
      @click="addDeparture"
      id="addDeparture"
      type="button"
      class="
        flex
        items-center
        mt-2
        lg:mt-6 lg:mb-0
        pl-2
        lg:pl-0
        mb-2
        p-0
        bg-transparent
        text-sm
        border-b-1 border-transparent
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mr-1 fill-current"
        height="16"
        width="16"
        viewBox="0 0 12 12"
      >
        <path
          d="M11.6 3.7c.3.8.4 1.5.4 2.3s-.1 1.5-.4 2.3c-.3.7-.7 1.4-1.3 2-.6.6-1.2 1-2 1.3-.8.3-1.5.4-2.3.4s-1.5-.1-2.3-.4c-.7-.3-1.4-.7-2-1.3-.6-.6-1-1.2-1.3-2S0 6.8 0 6s.1-1.5.4-2.3.7-1.4 1.3-2c.6-.6 1.2-1 2-1.3S5.2 0 6 0s1.5.1 2.3.4 1.4.7 2 1.3c.5.6 1 1.3 1.3 2zm-5 5.8l.1-2.9h2.9V5.4h-3v-3H5.4v2.9h-3v1.2h3v3h1.2z"
        />
      </svg>
      Add another airport
    </button>
  </div>
</template>

<script>
import dropdownArrowSvg from "../../components/svg/gadgetDropDownArrow.vue";

export default {
  props: { departures: Array, validationErrors: String },
  components: { dropdownArrowSvg },
  data() {
    return {
      selectedDeparturesValues: ["", "", ""],
      departureDropdowns: [],
      departuresMap: require("../../resources/departures"),
    };
  },
  created() {
    if (!this.departures || this.departures.length === 0) {
      //Default with Select Location. Set to London All Airports
      this.selectedDeparturesValues[0] = "";
      this.departureDropdowns.push({
        elementId: "holidays-departure-0",
        closable: false,
        hasRegional: true,
      });
      this.$store.dispatch("addDeparture", {
        elementId: `holidays-departure-0`,
        value: this.selectedDeparturesValues[0],
      });
    } else {
      this.departures.forEach((value, idx) => {
        this.selectedDeparturesValues[idx] = value;
        this.$store.dispatch("addDeparture", {
          elementId: `holidays-departure-${idx}`,
          value: value,
        });
      });

      if (this.departures.length > 0) {
        this.departures.forEach((value, idx) => {
          this.departureDropdowns.push({
            elementId: `holidays-departure-${idx}`,
            closable: idx > 0,
            hasRegional: idx === 0,
          });
        });
      }
    }
  },
  methods: {
    addDeparture() {
      if (this.departureDropdowns.length < 3) {
        const randomId = `holidays-departure-${this.randomString()}`;
        //Watch out on closable, hasRegional!. The new obj is being pushed, so lengths are calculated as -1 prior to push() happening!
        this.departureDropdowns.push({
          elementId: randomId,
          closable: this.departureDropdowns.length !== 0,
          hasRegional: this.departureDropdowns.length === 0,
        });

        //Push the empty select Location or handleDepartureRemove will fail
        this.$store.dispatch("addDeparture", {
          elementId: randomId,
          value: "",
        });
        this.$generalClick({
          category: "Search Gadget Interaction",
          label: "Add Airport",
        });
        this.$ga4GeneralClick({
          event: "search_gadget",
          product: "holidays",
          module: "add airport",
          action: "click",
        });
      }
    },
    showAddDeparture() {
      return (
        this.departureDropdowns.length > 0 && this.departureDropdowns.length < 3
      );
    },
    handleFocusDeparture(idx) {
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: "Open Depart From",
      });

      let ga4Module = `departure from`
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: idx > 0 ? `${ga4Module} ${idx + 1}` : ga4Module,
        action: "open",
      });
    },
    handleSelectDeparture(event, idx) {
      this.$store.dispatch("addDeparture", {
        elementId: event.target.id,
        value: event.target.value,
      });

      const selectedIndex = event.target.options.selectedIndex;
      if (selectedIndex > -1) {
        const selectedObj = event.target.options[selectedIndex]
        this.$generalClick({
          category: "Search Gadget Interaction",
          label: `Depart From_${selectedObj.text}`,
        });

        let ga4Module = `departure from`
        this.$ga4GeneralClick({
          event: "search_gadget",
          product: "holidays",
          module: idx > 0 ? `${ga4Module} ${idx + 1}` : ga4Module,
          action: "select",
          content: selectedObj.value,
          content_display: selectedObj.text,
        });
      }
    },
    handleDepartureRemove(event, elementIdToRemove) {
      const departureDropdowns = this.$store.getters.departures;
      const departureDropdownValueToDelete = departureDropdowns.find(
        (depDropdown) => {
          return depDropdown.elementId === elementIdToRemove;
        }
      );

      const selectedDeparturesValueToRemove = this.selectedDeparturesValues
        .filter(
          (departure) => departure === departureDropdownValueToDelete.value
        )
        .toString();

      const idx = this.selectedDeparturesValues.indexOf(
        selectedDeparturesValueToRemove
      );
      this.selectedDeparturesValues.splice(idx, 1);
      this.selectedDeparturesValues.push("");

      this.departureDropdowns = this.departureDropdowns.filter(
        (departureDropdown) => {
          if (departureDropdown.elementId !== elementIdToRemove) {
            return departureDropdown.elementId;
          }
        }
      );
      this.$store.dispatch("removeDeparture", elementIdToRemove);
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: "Remove Airport",
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "remove airport",
        action: "click",
      });
    },
    randomString() {
      return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      );
    },
  },
  computed: {
    showValidationErrors() {
      const departuresMap = [
        ...new Set(this.$store.getters.departures.map((d) => d.value)),
      ];

      if (
        departuresMap.length === 1 &&
        departuresMap[0] === "" &&
        this.validationErrors
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
