import { render, staticRenderFns } from "./chevron.vue?vue&type=template&id=4cec5280&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "f9242c08"
  
)

export default component.exports