<template>
  <div class="w-full relative p-2 pb-4 bg-white text-secondary block rounded shadow">
    <div class="gadget-divide"></div>
    <form
      id="holidaysGadget"
      autocomplete="off"
      @submit.prevent="handleSubmit"
      class="flex flex-wrap m-0 w-full"
      v-bind:class="{ 'sg__global-error': validationErrors.gadget }"
    >
      <div class="w-full" v-if="validationErrors.gadget">
        <span
          class="block text-grey-lighter bg-signal-error text-sm p-1 pl-2 w-full"
          >{{ validationErrors.gadget }}</span
        >
      </div>

      <Departure
        :departures="data.departures"
        :validationErrors="validationErrors.departures"
      ></Departure>

      <Destination
        :destination="data.destination"
        :validationErrors="validationErrors.destination"
      ></Destination>

      <div class="w-1/2 lg:w-2/12 mb-2 md:pr-1 lg:pr-0 sg__date-col">
        <DepartureDate
          :departureDate="data.departureDate"
          :validationErrors="validationErrors.departureDate"
        ></DepartureDate>
      </div>

      <div class="w-1/2 lg:w-1/12 mb-2 md:pl-1 lg:pl-0 sg__nights-col">
        <Nights :nights="data.nights"></Nights>
      </div>

      <div
        class="w-1/2 lg:w-1/12 md:pr-1 lg:pr-0 flex items-start sg__adult-col"
      >
        <Adults :adults="data.adults"></Adults>
      </div>
      <div
        class="w-1/2 lg:w-1/12 flex md:pl-1 lg:pl-0 items-start sg__child-col"
      >
        <Children
          :children="data.children"
          :validationErrors="validationErrors.children"
        ></Children>
      </div>

      <div
        class="w-full md:w-1/2 lg:w-1/12 flex m-0 mt-2 md:pr-1 lg:pr-0 lg:mt-4 mb-4 lg:absolute items-center sg__filters"
      >
        <span class="hidden lg:block pt-2 pb-2 text-secondary text-sm"
          >Advanced filters:</span
        >
        <BoardCatering :boardBasis="data.boardBasis"></BoardCatering>
        <Stars :starRating="data.starRating"></Stars>
      </div>

      <div class="w-full lg:w-1/12">
        <button
          data-cy="holidays-gadget-submit"
          type="submit"
          value="Search"
          class="w-full p-0 bg-text-link hover:bg-text-link-dark text-white rounded flex items-center justify-center text-xl md:text-base h-10"
        >
          <SearchIcon></SearchIcon>
          Search
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Departure from "../components/holidaysGadget/Departure.vue";
import Destination from "../components/holidaysGadget/Destination.vue";
import DepartureDate from "../components/holidaysGadget/DepartureDate.vue";
import Nights from "../components/holidaysGadget/Nights.vue";
import Adults from "../components/holidaysGadget/Adults.vue";
import Children from "../components/holidaysGadget/Children.vue";
import BoardCatering from "../components/holidaysGadget/BoardCatering.vue";
import Stars from "../components/holidaysGadget/Stars.vue";
import SearchIcon from "../components/svg/gadgetSearchIcon.vue";
import { mapGetters } from "vuex";
import Constants from "../Constants";

export default {
  props: { data: Object },
  components: {
    Departure,
    Destination,
    DepartureDate,
    Nights,
    Adults,
    Children,
    BoardCatering,
    Stars,
    SearchIcon,
  },
  data() {
    return {
      validationErrors: [],
      childrenCount: null,
      departuresMap: require("../resources/departures"),
    };
  },
  methods: {
    sendToGa(label, action, dimensions) {
      if (this.$generalClick !== undefined) {
        this.$generalClick({
          category: "Search Gadget Interaction",
          action: action ? action : "Error",
          label,
          dimensions,
        });
      }
    },
    handleSubmit() {
      this.$store.dispatch("validateGadget");
      this.validationErrors = this.$store.getters.validationErrors;

      if (Object.entries(this.validationErrors).length === 0) {
        this.sendToGa("Holidays Search Button", "Click", {
          dimension34: this.departures
            .map((d) => {
              if (d.value) {
                return this._getFriendlyDepartureName(d.value);
              }
            })
            .filter(Boolean)
            .join(", "),
          dimension35: this.destination.name,
          dimension36: this.departureDate,
          dimension37: this.nights,
          dimension38: this.adults,
          dimension39: this.children.length,
          dimension99: this.starRating.join(","),
          dimension100: this.boardBasis.join(","),
        });

        const ga4Date = new Date(this.departureDate)
        let ga4obj = {
          event: "search_gadget",
          product: "holidays",
          module: "buttonSearch",
          action: "search deal",
          destination: this.destination.name,
          departure_date: new Intl.DateTimeFormat('en-GB').format(ga4Date),
          duration: `${this.nights} nights`,
          number_of_adults: this.adults,
          number_of_children: this.children.length,
          children_ages: this.children
            .map((d) => {
              return d.value.toString()
            })
            .join(", "),
          board_basis: this.boardBasis
            .map((b)=>{
              return Constants.BOARD_BASIS_MAP.find(({ value }) => value === b).label;
            })
            .join(", "),
          star_rating: this.starRating.join(",") 
        }
        this.departures.forEach((loc, idx) => {
          ga4obj[idx > 0 ? `departure_airport_${idx + 1}` : `departure_airport`] = this._getFriendlyDepartureName(loc.value)
        })

        this.$ga4GeneralClick(ga4obj)
        this.$store.dispatch("submitGadget");        
      } else {
        if (this.validationErrors.departures) {
          this.sendToGa("Holidays Flying From Validation");
          this.$ga4GeneralClick({
            event: "search_gadget",
            product: "holidays",
            module: "departure from",
            action: "validation error"
          })
        }

        if (this.validationErrors.destination) {
          this.sendToGa("Holidays Destination Validation");
          this.$ga4GeneralClick({
            event: "search_gadget",
            product: "holidays",
            module: "destination",
            action: "validation error"
          })
        }

        if (this.validationErrors.children) {
          this.sendToGa("Holidays Child Ages Validation");
          this.$ga4GeneralClick({
            event: "search_gadget",
            product: "holidays",
            module: "children age",
            action: "validation error"
          })
        }

        if (this.validationErrors.departureDate) {
          this.$ga4GeneralClick({
            event: "search_gadget",
            product: "holidays",
            module: "departure date",
            action: "validation error"
          })
        }

        if (this.validationErrors.gadget) {
          this.sendToGa("Holidays Number Of Travelers Validation");
        }
        window.scrollTo(0, 0);
      }
    },
    _getFriendlyDepartureName(departure) {
      const locations = this.departuresMap.airports.concat(
        this.departuresMap.regions
      );

      for (let location of locations) {
        if (location.value === departure) {
          return location.label;
        }
      }
    },
    toSentenceCase(str) {
      const newStr = str.split('-')
        .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');
      return(newStr);
    },
    pushToDataLayer() {
      const destinationCountry = this.$store.getters.advertData.country ? this.$store.getters.advertData.country : '(country not set)';
      const destinationCity = this.$store.getters.advertData.location ? this.$store.getters.advertData.location : '(city not set)';
      const destinationUrl = this.$store.getters.destinationUrl ? this.$store.getters.destinationUrl : '(holiday type not set)';
      const holidayType = destinationUrl.split("/")[3];
      
      if(holidayType) {
        window.dataLayer.push({
          'event': 'holidays_page_view',
          'destination_country': destinationCountry,
          'destination_city': destinationCity,
          'holiday_type': this.toSentenceCase(holidayType)
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "departures",
      "destination",
      "departureDate",
      "nights",
      "adults",
      "children",
      "starRating",
      "boardBasis",
    ]),
  },
  mounted() {
    this.pushToDataLayer();
  },
};
</script>
<style lang="scss">
.gadget-divide {
  height: 1px;
  @apply bg-grey-dark absolute hidden;
  // top: calc(50% + 10px);
  top: 70px;
  width: calc(100% - 20px);
  @screen lg {
    @apply block;
  }
}
</style>