<template>
  <div class="w-full inline-block relative">
    <div class="bg-white rounded-tr-1 rounded-br-1 md:rounded lg:rounded-none border-1 border-l-0 md:border-l-1 lg:border-0 lg:border-l-1 border-grey-dark">
      <label for="numChild" class="absolute pt-1 pl-2 text-xs"
        >Child (0-17)</label
      >
      <div class="relative flex items-center">
        <select
          id="numChild"
          class="appearance-none w-full bg-transparent relative z-1 p-1 pt-4 pl-2 pr-6 text-lg font-bold h-10"
          v-model="numChildren"
          @focus="handleFocusChildren"
          @change="handleSelectChildren($event)"
        >
          <option v-for="n in 5" :value="n - 1" :key="`numChild-${n - 1}`">
            {{ n - 1 }}
          </option>
        </select>
        <dropdownArrowSvg></dropdownArrowSvg>
      </div>
    </div>

    <div
      class="bg-white flex mt-2 relative md:absolute lg:relative rounded-1 lg:rounded-none sg__child-ages"
      v-if="childrenAges.length > 0"
    >
      <div
        class="relative flex items-center p-0 border-1 lg:border-0 lg:border-t-1 border-grey-dark lg:rounded-none"
        v-for="(child, idx) in childrenAges"
        :key="`childAges-${idx}`"
        v-bind:class="{
          'border-l-0 lg:border-l-1': idx !== 0,
          'rounded-tl-1 rounded-bl-1' : idx === 0 && childrenAges.length > 1,
          'rounded-tr-1 rounded-br-1' : idx + 1 === childrenAges.length && childrenAges.length > 1,
          'w-full rounded': childrenAges.length === 1,
          'w-1/2': childrenAges.length === 2,
          'w-4/12': childrenAges.length === 3,
          'w-3/12': childrenAges.length === 4,
          'bg-grey-light sg__error': validateThisAge(idx)
        }"
      >
        <div class="w-full">
          <label
            v-if="!validateThisAge(idx)"
            :for="`childAges-${idx}`"
            class="absolute pt-1 pl-2 text-xs"
            >Age</label
          >
          <label
            data-cy="gadget-childage-error"
            v-if="validateThisAge(idx)"
            :for="`childAges-${idx}`"
            class="absolute pt-1 pl-2 text-xs text-signal-error"
            v-bind:class="{ 'text-signal-error': validateThisAge(idx) }"
            >Age</label
          >
          <select
            :id="`childAges-${idx}`"
            v-model="childrenAges[idx]"
            @focus="handleFocusChildrenAge"
            @change="handleSelectAge($event)"
            class="appearance-none w-full bg-transparent relative z-1 p-1 pt-4 pl-2 pr-6 text-lg font-bold h-10"
            v-bind:class="{ 'text-signal-error': validateThisAge(idx) }"
          >
            <option value="?" selected>?</option>
            <option v-for="(age, idx) in maxAge" :value="idx" :key="idx">
              {{ idx }}
            </option>
          </select>
          <dropdownArrowSvg
            :validation-errors="validateThisAge(idx)"
          ></dropdownArrowSvg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdownArrowSvg from "../../components/svg/gadgetDropDownArrow.vue";

export default {
  props: { children: Array, validationErrors: String },
  components: { dropdownArrowSvg },
  data() {
    return {
      childrenAges: this.children || [],
      numChildren: this.children ? this.children.length : 0,
      maxAge: 18,
    };
  },
  created() {
    this.childrenAges.forEach((child, idx) => {
      this.$store.dispatch("addChildrenWithAges", {
        id: `childAges-${idx}`,
        value: child,
      });
    });
    this.$store.dispatch("setNumChildren", this.numChildren);
  },
  methods: {
    validateThisAge(idx) {
      return this.validationErrors && this.childrenAges[idx] === "?";
    },
    handleFocusChildrenAge(){
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "children age",
        action: "open",
      });
    },
    handleFocusChildren() {
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: "Open Number of Children",
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "children",
        action: "open",
      });
    },
    handleSelectChildren(event) {
      this.numChildren = parseInt(event.target.value);
      if (isNaN(this.numChildren)) {
        this.numChildren = 0;
      }

      if (this.childrenAges.length < this.numChildren) {
        const newUnselectedAges = Array(
          this.numChildren - this.childrenAges.length
        ).fill("?", 0, this.numChildren - this.childrenAges.length);
        this.childrenAges = this.childrenAges.concat(newUnselectedAges);
      }

      this.childrenAges.splice(this.numChildren, this.childrenAges.length);
      this.$store.dispatch("spliceChildrenAges", this.numChildren);

      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Number of Children_${this.numChildren}`,
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "children",
        action: "select",
        content_display: this.numChildren
      });
    },
    handleSelectAge(event) {
      let age = parseInt(event.target.value);
      if (isNaN(age)) {
        age = "?";
      }

      this.$store.dispatch("addChildrenWithAges", {
        id: event.target.id,
        value: age,
      });

      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Age of Child_${age}`,
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "children age",
        action: "select",
        content_display: age
      });
    },
  },
};
</script>

<style lang="scss">
.sg__child-ages {
  margin-left: calc(-100% - 2px);
  width: calc(200% + 2px);
  @screen md {
    margin-left: 0;
    width: 100%;
  }
  @screen lg {
    margin-left: calc(-170% - 2px);
    width: calc(270% + 2px);
  }
}
</style>
