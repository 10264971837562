<template>
  <div
    class="bg-white w-full inline-block border-1 lg:border-0 lg:border-l-1 border-grey-dark rounded-tl-1 rounded-bl-1 md:rounded-1 lg:rounded-none sg__date-wrap"
    v-bind:class="{ 'relative bg-grey-light sg__error': showValidationErrors }"
  >
    <label
      v-if="!showValidationErrors"
      for="holidaysDepart"
      class="absolute pt-1 pl-2 text-xs"
      >Dep. date</label
    >
    <label
      v-if="showValidationErrors"
      for="holidaysDepart"
      class="absolute pt-1 pl-2 text-xs text-signal-error"
      >{{ validationErrors }}</label
    >
    <div class="relative flex items-center">
      <flat-pickr
        id="holidaysDepart"
        data-cy="holidays-depart"
        name="date"
        type="text"
        class="relative z-1 p-1 pt-4 pl-2 w-full bg-transparent text-lg font-bold placeholder-black cursor-pointer h-10"
        :class="{ 'placeholder-signal-error': showValidationErrors }"
        placeholder="Enter Date"
        readonly="true"
        v-model="departureDateSelected"
        :config="config"
        @on-change="handleDepartureDateInput"
        @on-open="
          $generalClick({
            category: 'Search Gadget Interaction',
            label: 'Open Departure Date',
          });
          $ga4GeneralClick({
            event: 'search_gadget',
            product: 'holidays',
            module: 'departure date',
            action: 'open',
          });
        "
      ></flat-pickr>
      <dropdownArrowSvg
        :validation-errors="showValidationErrors"
      ></dropdownArrowSvg>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import HelperFunctions from "../../HelperFunctions";
import dropdownArrowSvg from "../../components/svg/gadgetDropDownArrow.vue";
export default {
  props: { departureDate: String, validationErrors: String },
  components: { dropdownArrowSvg },
  data() {
    return {
      datePickerCookieName: "dateConfigHolidays",
      datePickerConfig: JSON.parse('{"minDate":"2021-05-17"}'),
      departureDateSelected: null,
      config: {
        disableMobile: true,
        disable: [],
        locale: {
            firstDayOfWeek: 1,
        },
        minDate: null,
        maxDate: null,
      },
      dates: {
        minDate: HelperFunctions.dateIncrement(1),
        maxDate: HelperFunctions.dateIncrement(658),
        today: new Date(),
      },
    };
  },
  beforeMount() {
    this.setDepartureDate();
    this.setConfigFromCookie();
    Vue.set(this.config, "dateFormat", "j M y");
  },
  computed: {
    showValidationErrors() {
      if (!this.$store.getters.departureDate && this.validationErrors) {
        return true;
      }
      return false;
    },
  },
  methods: {
    setConfigFromCookie() {
      const datePickerConfigString = HelperFunctions.readCookie(
        this.datePickerCookieName
      );
      if (
        datePickerConfigString &&
        HelperFunctions.isJson(datePickerConfigString)
      ) {
        this.datePickerConfig = JSON.parse(datePickerConfigString);
      }
      this.setMinMaxDates();
      this.setDisabledDates();
    },
    _setDateLimit(limitDate) {
      if (this.datePickerConfig && this.datePickerConfig[limitDate]) {
        const date = new Date(this.datePickerConfig[limitDate]);
        if (
          !isNaN(date.getTime()) &&
          date.getTime() > this.dates.today.getTime()
        ) {
          this.dates[limitDate] = date;
        }
      }
      Vue.set(this.config, limitDate, this.dates[limitDate]);
    },
    setMinMaxDates() {
      //Default both dates when cookie has max <= min
      if (
        this.datePickerConfig &&
        this.datePickerConfig.minDate &&
        this.datePickerConfig.maxDate &&
        new Date(this.datePickerConfig.minDate).getTime() >=
          new Date(this.datePickerConfig.maxDate).getTime()
      ) {
        Vue.set(
          this.datePickerConfig,
          "minDate",
          HelperFunctions.dateIncrement(1)
        );
        Vue.set(
          this.datePickerConfig,
          "maxDate",
          HelperFunctions.dateIncrement(658)
        );
      }
      this._setDateLimit("minDate");
      this._setDateLimit("maxDate");

      //Default both dates when min >= max when one of them has been defaulted
      if (
        this.dates &&
        this.dates.minDate &&
        this.dates.maxDate &&
        this.dates.minDate.getTime() >= this.dates.maxDate.getTime()
      ) {
        Vue.set(this.config, "minDate", HelperFunctions.dateIncrement(1));
        Vue.set(this.config, "maxDate", HelperFunctions.dateIncrement(658));
      }
    },
    setDisabledDates() {
      if (
        this.datePickerConfig &&
        this.datePickerConfig.disabledDateRanges &&
        this.datePickerConfig.disabledDateRanges.length
      ) {
        this.datePickerConfig.disabledDateRanges.forEach((range) => {
          const disabledDateRanges = this.checkDisabledDateRanges(range);
          if (disabledDateRanges) {
            this.config.disable.push(disabledDateRanges);
          }
        });
      }
    },
    checkDisabledDateRanges(range) {
      if (range && range.from && range.to) {
        const from = new Date(range.from);
        const to = new Date(range.to);
        if (from < to) {
          return range;
        } else if (from.getTime() === to.getTime()) {
          return range.from;
        }
      }
    },
    setDepartureDate() {
      if (this.departureDate) {
        const date = this.departureDate;
        this.$store.dispatch("setDepartureDate", date);
        this.departureDateSelected = HelperFunctions.dateParseToReadable(date);
      }
    },
    handleDepartureDateInput(selectedDates, dateString) {
      this.$store.dispatch(
        "setDepartureDate",
        HelperFunctions.parseToISODate(dateString)
      );
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Departure Date_${HelperFunctions.parseToISODate(dateString)}`,
      });
      let ga4Date = new Date(selectedDates)
      this.$ga4GeneralClick({
        event: 'search_gadget',
        product: 'holidays',
        module: 'departure date',
        action: 'select',
        content_display: new Intl.DateTimeFormat('en-GB').format(ga4Date)
      });
    },
  },
};
</script>
