var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[(!_vm.showValidationErrors)?_c('label',{staticClass:"absolute pt-1 pl-2 text-xs",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.showValidationErrors)?_c('label',{staticClass:"absolute pt-1 pl-2 text-xs text-signal-error",attrs:{"data-cy":(_vm.id + "-error"),"for":_vm.id}},[_vm._v(_vm._s(_vm.validationErrors))]):_vm._e(),_c('input',{ref:"input",staticClass:"z-1 p-1 pt-4 pl-2 w-full bg-transparent placeholder-black text-lg font-bold cursor-pointer h-10",class:{
      'text-signal-error placeholder-signal-error': _vm.showValidationErrors,
      'pr-8': _vm.endpointLocations,
    },attrs:{"data-cy":_vm.id,"type":"text","placeholder":_vm.placeholder,"id":_vm.id,"name":_vm.id,"autocomplete":"off","aria-owns":(_vm.id + "-results"),"aria-expanded":_vm.autoItems && _vm.autoItems.length > 0,"aria-autocomplete":"list"},on:{"input":function($event){return _vm.updateValue($event.target.value)},"blur":_vm.blurAuto,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onEscape($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onArrowDown($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.onArrowUp($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event)}],"click":function($event){return _vm.focus($event.target)},"focus":function($event){_vm.$generalClick({
        category: 'Search Gadget Interaction',
        label: ("Click on " + _vm.label),
      });
      _vm.$ga4GeneralClick({
        event: 'search_gadget',
        product: _vm.channel.toLowerCase(),
        module: _vm.ga4Label,
        action: 'click',
      });}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.autoItems && _vm.autoItems.length > 0) || _vm.showNoResultsMessage),expression:"(autoItems && autoItems.length > 0) || showNoResultsMessage"}],staticClass:"absolute z-2 w-full autocomplete-wrapper",class:{
      'autocomplete-wrapper--any': _vm.showAnyDestination,
    }},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:((_vm.autoItems && _vm.autoItems.length > 0) || _vm.showNoResultsMessage),expression:"(autoItems && autoItems.length > 0) || showNoResultsMessage"},{name:"scroll-into-view",rawName:"v-scroll-into-view",value:(_vm.arrowCounter),expression:"arrowCounter"}],staticClass:"bg-white overflow-auto autocomplete",attrs:{"id":(_vm.id + "-results"),"data-cy":(_vm.id + "-autocomplete"),"role":"listbox","aria-expanded":_vm.autoItems && _vm.autoItems.length > 0}},[(_vm.showNoResultsMessage)?_c('li',{staticClass:"p-2 text-lg cursor-pointer",attrs:{"data-cy":(_vm.id + "-no-results")}},[_vm._v("\n        No matching locations\n      ")]):_vm._e(),_vm._l((_vm.autoItems),function(item,i){return _c('li',{key:item.id,staticClass:"flex items-center hover:bg-primary-lighter p-2 text-lg cursor-pointer",class:{
          'bg-primary-lighter': i === _vm.arrowCounter,
          'bg-white absolute top-full w-full':
            _vm.showAnyDestination && i === _vm.autoItems.length - 1,
        },attrs:{"role":"option","aria-selected":i === _vm.arrowCounter,"tabindex":"-1"},on:{"mousedown":function($event){return _vm.selectItem(item, i)}}},[(item.type === 'airport')?_c('Icon',{staticClass:"mr-2 min-h-5 min-w-5 h-5 w-5",attrs:{"name":"Airplane","width":"36","height":"36","viewBox":"0 0 24 24"}}):_vm._e(),(_vm.isCarhireDowntown(item))?_c('Icon',{staticClass:"mr-2 min-h-5 min-w-5 h-5 w-5",attrs:{"name":"CityLocation","width":"36","height":"36","viewBox":"0 0 24 24"}}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getDisplayName(item))}})],1)})],2)]),(_vm.endpointLocations)?_c('button',{staticClass:"absolute top-0 mt-4 right-0 mr-2",attrs:{"type":"button","aria-label":"Pick up near current location"},on:{"click":function($event){return _vm.useCurrentLocation()},"blur":_vm.blurAuto}},[_c('Icon',{ref:"GpsIcon",staticClass:"text-orange",attrs:{"name":"Gps"}}),_c('div',{ref:"Loader",staticClass:"loader absolute top-0 right-0 invisible"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }