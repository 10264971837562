<template>
  <div class="w-1/2 lg:w-4/12 pl-2 md:pl-0 lg:pl-2 lg:pr-2">
    <div
      class="relative flex items-center"
      :class="{ 'bg-white': showOptions }"
    >
      <svg
        aria-hidden="true"
        data-cy="gadget-stars-icon"
        class="absolute left-0 ml-2 text-text-link fill-current"
        width="30"
        height="20"
        viewBox="0 0 30 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Stars icon</title>
        <g fill-rule="evenodd">
          <path
            d="M5.846154 17.17117907L2.75598406 19.5l1.20553452-3.73256094L.846154 13.47416861l3.83523126.0219714L5.846154 9.75l1.16476874 3.74614001 3.83523126-.0219714-3.11536458 2.29327045L8.93632394 19.5zM15.846154 17.17117907L12.7559841 19.5l1.2055345-3.73256094-3.1153646-2.29327045 3.8352313.0219714L15.846154 9.75l1.1647687 3.74614001 3.8352313-.0219714-3.1153646 2.29327045L18.9363239 19.5z"
          />
          <path
            d="M25.846154 17.17117907L22.7559841 19.5l1.2055345-3.73256094-3.1153646-2.29327045 3.8352313.0219714L25.846154 9.75l1.1647687 3.74614001 3.8352313-.0219714-3.1153646 2.29327045L28.9363239 19.5z"
          />
          <g>
            <path
              d="M10.846154 7.38490276L7.75598406 9.75l1.2391881-3.74377094L5.846154 3.72416861l3.85603031.05131956L10.846154 0l1.14396969 3.77548817 3.85603031-.05131956-3.14901816 2.28206045L13.93632394 9.75z"
            />
            <path
              d="M20.846154 7.38490276L17.7559841 9.75l1.2391881-3.74377094-3.1490182-2.28206045 3.8560303.05131956L20.846154 0l1.1439697 3.77548817 3.8560303-.05131956-3.1490182 2.28206045L23.9363239 9.75z"
            />
          </g>
        </g>
      </svg>
      <button
        aria-haspopup="true"
        v-bind:aria-expanded="showOptions ? 'true' : 'false'"
        id="holidaysStars"
        class="appearance-none border-0 rounded-none bg-transparent pt-2 pr-6 pb-2 pl-10 w-full relative z-1 text-left text-sm truncate cursor-pointer"
        :class="{
          'border-1 border-primary border-b-0 text-secondary': showOptions,
          'border-b-1 border-grey-dark': !showOptions,
        }"
        @click="toggleOptions($event)"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
        @keydown.space="onEnter"
        @keydown.tab="closeOptions"
        @keydown.esc="closeOptions"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          width="18"
          viewBox="0 0 185.343 185.343"
          class="fill-current transform rotate-90 absolute right-0 mr-2"
          :class="{ 'text-primary': showOptions }"
        >
          <path
            d="M51.707 185.343a10.692 10.692 0 0 1-7.593-3.149 10.724 10.724 0 0 1 0-15.175l74.352-74.347L44.114 18.32c-4.194-4.194-4.194-10.987 0-15.175 4.194-4.194 10.987-4.194 15.18 0l81.934 81.934c4.194 4.194 4.194 10.987 0 15.175l-81.934 81.939a10.678 10.678 0 0 1-7.587 3.15z"
          />
        </svg>
        {{ starRatingDisplay }}
      </button>
      <fieldset
        v-show="showOptions"
        :aria-hidden="showOptions ? 'false' : 'true'"
      >
        <legend class="absolute -left-9999">Star rating options</legend>
        <ul
          id="holidaysStarsList"
          role="menu"
          class="absolute z-1 top-full left-0 bg-white w-full"
          :class="{ 'border-1 border-primary border-t-0': showOptions }"
        >
          <li v-for="(star, i) in stars" :key="star" role="menuitem">
            <input
              v-model="starRatingSelected"
              @change="handleStarRating($event)"
              :data-vars-star-rating="`Stars_${star} stars`"
              :id="`gadget_stars_${star}`"
              class="hidden"
              type="checkbox"
              name="starRating"
              :value="star"
            />
            <label
              class="flex w-full p-2 relative items-center hover:bg-primary-lighter"
              :class="{ 'bg-primary-lighter': i === arrowCounter }"
              :for="`gadget_stars_${star}`"
            >
              <span class="bg-grey-lighter flex items-center justify-center mr-2 h-5 min-w-5 w-5">
                <svg
                  v-if="isSelected(star)"
                  xmlns="http://www.w3.org/2000/svg"
                  class="text-primary fill-current h-3 w-3"
                  height="11"
                  width="14"
                  viewBox="0 0 15 12"
                >
                  <path
                    d="M15 1.6L4.6 12 0 7.3l1.5-1.6 3.1 3.1L13.4 0 15 1.6z"
                  />
                </svg> </span
              >{{ star }} stars</label
            >
          </li>
        </ul>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  props: { starRating: Array },
  data() {
    return {
      showOptions: false,
      stars: [2, 3, 4, 5],
      starRatingSelected: this.starRating || [],
      arrowCounter: -1,
    };
  },
  created() {
    this.starRatingSelected.forEach((rating) => {
      if (rating >= 2 && rating <= 5) {
        this.$store.dispatch("addStarRating", rating);
      }
    });
  },
  methods: {
    isSelected(val) {
      return this.starRatingSelected.indexOf(val) > -1;
    },
    toggleOptions(event) {
      event.preventDefault();
      this.showOptions = !this.showOptions;
      if (this.showOptions) {
        this.$generalClick({
          category: "Search Gadget Interaction",
          label: "Open Stars Filter",
        });
        this.$ga4GeneralClick({
          event: "search_gadget",
          product: "holidays",
          module: "star rating",
          action: "open",
        });
      }
    },
    handleStarRating(event) {
      const checkboxStatus = event.target.checked;
      const checkboxValue = parseInt(event.target.value);
      if (checkboxStatus) {
        if (!isNaN(checkboxValue)) {
          this.$store.dispatch("addStarRating", checkboxValue);
        }
      } else {
        if (!isNaN(checkboxValue)) {
          this.$store.dispatch("removeStarRating", checkboxValue);
        }
      }
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Stars_${checkboxValue} stars`,
        action: `${checkboxStatus ? "Select" : "Deselect"}`,
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "star rating",
        content: event.target.value,
        content_display: `${event.target.value} stars`,
        action: `${checkboxStatus ? "select" : "deselect"}`
      })
    },
    sendGa4CloseEvent(){
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "star rating",
        action: "close",
      });
    },
    closeOptions() {
      if(this.showOptions){
        this.sendGa4CloseEvent()
      }
      this.showOptions = false;
      this.arrowCounter = -1;
    },
    onArrowDown(evt) {
      evt.preventDefault();
      if (this.showOptions) {
        if (this.arrowCounter < this.stars.length - 1) {
          this.arrowCounter = this.arrowCounter + 1;
        } else {
          this.arrowCounter = 0;
        }
      }
    },
    onArrowUp(evt) {
      evt.preventDefault();
      if (this.showOptions) {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        } else {
          this.arrowCounter = this.stars.length - 1;
        }
      }
    },
    onEnter(evt) {
      evt.preventDefault();
      if (!this.showOptions) {
        this.showOptions = true;
        this.$generalClick({
          category: "Search Gadget Interaction",
          label: "Open Stars Filter",
        });
        this.$ga4GeneralClick({
          event: "search_gadget",
          product: "holidays",
          module: "star rating",
          action: "open",
        });
      } else {
        if (this.arrowCounter >= 0) {
          const val = this.stars[this.arrowCounter];
          const ga = {
            category: "Search Gadget Interaction",
            label: `Stars_${val} stars`,
          };
          const ga4 = {
            event: "search_gadget",
            product: "holidays",
            module: "star rating",
            content: val,
            content_display: `${val} stars`
          }
          if (this.isSelected(val)) {
            this.starRatingSelected = this.starRatingSelected.filter(
              (star) => star !== val
            );
            this.$store.dispatch("removeStarRating", val);
            ga.action = "Deselect";
            ga4.action = "deselect";
          } else {
            this.starRatingSelected.push(val);
            this.$store.dispatch("addStarRating", val);
            ga.action = "Select";
            ga4.action = "select";
          }
          this.$generalClick(ga);
          this.$ga4GeneralClick(ga4)
        } else {
          this.closeOptions();
        }
      }
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        if (this.showOptions) {
          this.closeOptions();
        }
      }
    },
  },
  computed: {
    starRatingDisplay() {
      if (this.starRatingSelected.length === 0) {
        return "Any";
      } else if (this.starRatingSelected.length === 1) {
        return `${this.starRatingSelected[0]} Stars`;
      }
      return "Multiple";
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
</style>
