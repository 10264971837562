<template>
  <div class="bg-white w-full inline-block border-1 border-l-0 md:border-l-1 lg:border-0 lg:border-l-1 border-grey-dark rounded-tr-1 rounded-br-1 md:rounded lg:rounded-none">
    <label for="nights" class="absolute pt-1 pl-2 text-xs">Nights</label>
    <div class="relative flex items-center">
      <select
        id="nights"
        name="nights"
        class="appearance-none w-full bg-transparent relative z-1 p-1 pt-4 pl-2 pr-6 text-lg font-bold h-10"
        v-model="nightsSelected"
        @focus="handleFocusNights"
        @change="handleSelectNights($event)"
      >
        <optgroup label="Popular durations">
          <option
            v-for="popularDuration in popularDurations"
            :key="popularDuration"
            :value="popularDuration"
          >
            {{ popularDuration }}
          </option>
        </optgroup>
        <optgroup label="All durations">
          <option 
            v-for="n in allDurations" 
            :key="n" :value="n"
            :selected="n === 7"
          >
            {{ n }}
          </option>
        </optgroup>
      </select>
      <dropdownArrowSvg></dropdownArrowSvg>
    </div>
  </div>
</template>

<script>
import dropdownArrowSvg from "../../components/svg/gadgetDropDownArrow.vue";

export default {
  props: { nights: Number },
  components: { dropdownArrowSvg },
  data() {
    return {
      nightsSelected: this.nights || 7,
      popularDurations: [2, 4, 7, 10, 14],
      allDurations: 21,
    };
  },
  created() {
    this.$store.dispatch("addNights", this.nightsSelected);
  },
  methods: {
    handleFocusNights() {
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: "Open Duration",
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "nights",
        action: "open",
      });
    },
    handleSelectNights(event) {
      let nights = parseInt(event.target.value);
      if (isNaN(nights)) {
        nights = this.nightsSelected;
      }
      this.$store.dispatch("addNights", nights);
      this.$generalClick({
        category: "Search Gadget Interaction",
        label: `Duration_${nights}`,
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "holidays",
        module: "nights",
        action: "select",
        content_display: nights
      });
    },
  },
};
</script>
