<template>
  <div class="relative">
    <label
      v-if="!showValidationErrors"
      :for="id"
      class="absolute pt-1 pl-2 text-xs"
      >{{ label }}</label
    >
    <label
      :data-cy="`${id}-error`"
      v-if="showValidationErrors"
      :for="id"
      class="absolute pt-1 pl-2 text-xs text-signal-error"
      >{{ validationErrors }}</label
    >
    <input
      :data-cy="id"
      type="text"
      class="z-1 p-1 pt-4 pl-2 w-full bg-transparent placeholder-black text-lg font-bold cursor-pointer h-10"
      :class="{
        'text-signal-error placeholder-signal-error': showValidationErrors,
        'pr-8': endpointLocations,
      }"
      :placeholder="placeholder"
      :id="id"
      :name="id"
      ref="input"
      autocomplete="off"
      :aria-owns="`${id}-results`"
      v-bind:aria-expanded="autoItems && autoItems.length > 0"
      aria-autocomplete="list"
      @input="updateValue($event.target.value)"
      @blur="blurAuto"
      @keydown.esc="onEscape"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      @click="focus($event.target)"
      @focus="
        $generalClick({
          category: 'Search Gadget Interaction',
          label: `Click on ${label}`,
        });
        $ga4GeneralClick({
          event: 'search_gadget',
          product: channel.toLowerCase(),
          module: ga4Label,
          action: 'click',
        });
      "
    />
    <div
      class="absolute z-2 w-full autocomplete-wrapper"
      :class="{
        'autocomplete-wrapper--any': showAnyDestination,
      }"
      v-show="(autoItems && autoItems.length > 0) || showNoResultsMessage"
    >
      <ul
        :id="`${id}-results`"
        :data-cy="`${id}-autocomplete`"
        role="listbox"
        v-bind:aria-expanded="autoItems && autoItems.length > 0"
        v-show="(autoItems && autoItems.length > 0) || showNoResultsMessage"
        v-scroll-into-view="arrowCounter"
        class="bg-white overflow-auto autocomplete"
      >
        <li
          v-if="showNoResultsMessage"
          :data-cy="`${id}-no-results`"
          class="p-2 text-lg cursor-pointer"
        >
          No matching locations
        </li>
        <li
          v-for="(item, i) in autoItems"
          :key="item.id"
          @mousedown="selectItem(item, i)"
          class="flex items-center hover:bg-primary-lighter p-2 text-lg cursor-pointer"
          :class="{
            'bg-primary-lighter': i === arrowCounter,
            'bg-white absolute top-full w-full':
              showAnyDestination && i === autoItems.length - 1,
          }"
          role="option"
          v-bind:aria-selected="i === arrowCounter"
          tabindex="-1"
        >
          <Icon
            v-if="item.type === 'airport'"
            name="Airplane"
            class="mr-2 min-h-5 min-w-5 h-5 w-5"
            width="36"
            height="36"
            viewBox="0 0 24 24"
          />
          <Icon
            v-if="isCarhireDowntown(item)"
            name="CityLocation"
            class="mr-2 min-h-5 min-w-5 h-5 w-5"
            width="36"
            height="36"
            viewBox="0 0 24 24"
          />
          <span v-html="getDisplayName(item)"></span>
        </li>
      </ul>
    </div>

    <button
      v-if="endpointLocations"
      type="button"
      class="absolute top-0 mt-4 right-0 mr-2"
      aria-label="Pick up near current location"
      @click="useCurrentLocation()"
      @blur="blurAuto"
    >
      <Icon class="text-orange" name="Gps" ref="GpsIcon"></Icon>
      <div class="loader absolute top-0 right-0 invisible" ref="Loader"></div>
    </button>
  </div>
</template>
<script>
import { ScrollIntoView } from "../../directives/ScrollIntoView.js";
import Icon from "../../components/Icon.vue";

export default {
  name: "auto",
  props: {
    endpoint: String,
    endpointLocations: String,
    channel: String,
    gaLabel: String,
    ga4Label: String,
    label: String,
    id: String,
    placeholder: String,
    displayValue: String,
    value: Object,
    validationErrors: String,
    showValidationErrors: Boolean,
    showAnyDestination: Boolean,
  },
  components: { Icon },
  directives: {
    "scroll-into-view": ScrollIntoView,
  },
  data: function () {
    return {
      autoItems: [],
      selectedItem: this.value ? this.value.name : null,
      selectedItemType: null,
      selectedItemIndex: null,
      selectedStatus: this.value ? true : false,
      searchedText: "",
      searchedTextRegex: new RegExp("(" + this.searchedText + ")", "gi"),
      arrowCounter: -1,
      showNoResultsMessage: false,
    };
  },
  methods: {
    isCarhireDowntown(item) {
      if (item.type !== "airport" && this.channel === "Car Hire") {
        return true;
      }
    },
    updateVisibleText(val) {
      // TODO how to we get around this?
      //not nice having this but gets around what appears to be a bug in Chrome on Android: https://codepen.io/leads/full/gOmwrXa
      // using this function instead of v-model="selectedItem"
      this.$refs["input"].value = val;
    },
    onEscape() {
      this.resetAutoItems();
      this.resetArrowCounter();
    },
    onEnter(evt) {
      evt.preventDefault();
      if (this.autoItems.length > 0) {
        if (this.arrowCounter > -1) {
          this.selectItem(this.autoItems[this.arrowCounter], this.arrowCounter);
        } else {
          this.selectItem(this.autoItems[0], 0);
        }
      }
    },
    onArrowDown(evt) {
      evt.preventDefault();
      if (this.autoItems.length > 0) {
        if (this.arrowCounter < this.autoItems.length - 1) {
          this.arrowCounter = this.arrowCounter + 1;
        } else {
          this.arrowCounter = 0;
        }
      }
    },
    onArrowUp(evt) {
      evt.preventDefault();
      if (this.autoItems.length > 0) {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        } else {
          this.arrowCounter = this.autoItems.length - 1;
        }
      }
    },
    getDisplayName(item) {
      let searchedTextRegexp = new RegExp("(" + this.searchedText + ")", "gi");
      let html = item[this.displayValue].replace(
        searchedTextRegexp,
        "<strong>$1</strong>"
      );
      return html;
    },
    focus(el) {
      window.setTimeout(function () {
        el.setSelectionRange(0, 9999);
      }, 10);
    },
    blurAuto() {
      if (this.selectedStatus) {
        if (!this.selectedItem) {
          this.$emit("input", null);
        }

        this.resetAutoItems();
      } else {
        if (this.autoItems.length > 0 && this.arrowCounter > -1) {
          this.selectItem(this.autoItems[this.arrowCounter], this.arrowCounter);
        } else if (this.autoItems.length > 0 && this.arrowCounter === -1) {
          this.selectItem(this.autoItems[0], 0);
        } else if (this.showNoResultsMessage) {
          this.showNoResultsMessage = false;
          this.selectedItem = null;
          this.updateVisibleText(null);
          this.selectedItemType = null;
          this.$emit("input", null);
        } else {
          this.selectedStatus = false;
          this.resetAutoItems();
          this.selectedItem = null;
          this.updateVisibleText(null);
          this.selectedItemType = null;
          this.$emit("input", null);
        }
      }
    },
    updateValue(searchTerm, location = false) {
      // firefox on Android triggers an @input when an option is selected which then fires 'updateValue' function. This check stops that happening.
      let endpoint = location ? this.endpointLocations : this.endpoint;
      if (searchTerm !== this.selectedItem) {
        if (location) {
          searchTerm = `lat=${searchTerm.coords.latitude}&lon=${searchTerm.coords.longitude}`;
        }

        const anywhereObj = {
          id: "any",
          name: "Any Destination",
          type: "place",
          iata: null,
          searchableFrom: null,
        };

        this.resetArrowCounter();
        this.selectedStatus = false;
        this.showNoResultsMessage = false;
        this.searchedText = searchTerm;
        if (searchTerm.length > 2) {
          this.resetAutoItems();
          import(/* webpackChunkName: "tsm-axios" */ "axios").then((Axios) => {
            Axios.get(`${this.apiDomain}${endpoint}${searchTerm}`)
              .then((response) => {
                this.autoItems = response.data.locations

                if (this.showAnyDestination) {
                  this.autoItems.push(anywhereObj);
                }
                if (
                  this.autoItems &&
                  Array.isArray(this.autoItems) &&
                  response.data.locations.length === 0
                ) {
                  this.showNoResultsMessage = true;
                  this.$generalClick({
                    category: "Auto Complete",
                    label: `${this.channel} ${this.gaLabel} Failure`,
                    dimensions: { dimension82: this.searchedText },
                  });
                  this.$ga4GeneralClick({
                    event: "search_gadget",
                    product: this.channel.toLowerCase(),
                    module: this.ga4Label,
                    action: "autocomplete",
                    validation: "no matching destination",
                    content_display: searchTerm,
                  });
                }
              })
              .catch(() => {
                if (this.showAnyDestination) {
                  this.autoItems.push(anywhereObj);
                } else {
                  this.resetAutoItems();
                }
              });
          });
        } else {
          this.resetAutoItems();
        }
      }
    },
    selectItem(item, idx) {
      idx += 1;
      document.getElementById(`${this.id}-results`).scrollTop = 0;
      this.resetArrowCounter();
      this.selectedStatus = true;
      this.resetAutoItems();
      this.selectedItem = item[this.displayValue];
      this.updateVisibleText(item[this.displayValue]);
      this.selectedItemType = item.type;
      this.selectedItemIndex = idx;
      this.$emit("input", item);
      this.showNoResultsMessage = false;
      this.$generalClick({
        category: "Auto Complete",
        label: `${this.channel} ${this.gaLabel} Success`,
        dimensions: {
          dimension82: this.searchedText,
          dimension83: this.selectedItem,
          dimension88:
            this.channel === "Car Hire" && this.selectedItemType === "airport"
              ? "Airport Locations"
              : "Other Locations",
          dimension103: this.selectedItemIndex,
        },
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: this.channel.toLowerCase(),
        module: this.ga4Label,
        action: "select",
        content_display: this.selectedItem,
      });
    },
    resetAutoItems() {
      this.autoItems = [];
    },
    resetArrowCounter() {
      this.arrowCounter = -1;
    },
    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    async useCurrentLocation() {
      this.$generalClick({
        category: "Auto Complete",
        label: `${this.channel} use current location clicked`,
      });
      this.$ga4GeneralClick({
        event: "search_gadget",
        product: "car hire",
        module: "geolocalisation",
        action: "click",
      });
      let location;
      this.startLoader();
      try {
        location = await this.getLocation();
        this.updateValue(location, true);
        this.stopLoader();
        this.$refs["input"].focus();
        if (location) {
          this.$ga4GeneralClick({
            event: "search_gadget",
            product: "car hire",
            module: "geolocalisation",
            action: "validate",
          });
        }
      } catch (e) {
        this.stopLoader();
        this.$generalClick({
          category: "Auto Complete",
          label: `${this.channel} failed getting current location with error: ${e.message}`,
        });
        this.$ga4GeneralClick({
          event: "search_gadget",
          product: "car hire",
          module: "geolocalisation",
          action: "failure",
        });
        if (e.code === 1) {
          alert(
            "To use this function please allow your browser to use your current location."
          );
        }
      }
    },
    startLoader() {
      this.$refs.GpsIcon.$el.classList.add("invisible");
      this.$refs.Loader.classList.remove("invisible");
    },
    stopLoader() {
      this.$refs.GpsIcon.$el.classList.remove("invisible");
      this.$refs.Loader.classList.add("invisible");
    },
  },
  mounted() {
    this.apiDomain =
      window.location.hostname === "www.travelsupermarket.com"
        ? this.$store.getters.apiUrlProd
        : this.$store.getters.apiUrlTest;
  },
};
</script>
<style lang="scss">
.autocomplete-wrapper {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.56);
}

.autocomplete-wrapper--any {
  padding-bottom: 40px;
}

.autocomplete {
  max-height: 140px;
  @screen md {
    max-height: 300px;
  }
}

.autocomplete-wrapper--any ul li:last-child {
  top: calc(100% - 40px);
  box-shadow: 0px 4px 3px -3px rgba(0, 0, 0, 0.56);
}

.loader {
  width: 18px;
  padding: 2px;
  margin: 2px 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #f60;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
</style>